<template>
  <div class="newCreate">
    <div class="title">#申请人信息</div>
    <div class="list">
      <div class="list-title">部门：</div>
      <div class="list-content">{{ data.section }}</div>
    </div>
    <div class="list">
      <div class="list-title">姓名：</div>
      <div class="list-content">{{ data.adminName }}</div>
    </div>
    <div class="list">
      <div class="list-title">联系方式：</div>
      <div class="list-content">{{ data.adminMobile }}</div>
    </div>
    <div class="list">
      <div class="list-title">审批事项：</div>
      <div class="list-content">{{ typeList[data.typelist] }}</div>
    </div>
    <div class="list" v-if="['0', '1', '4', '7', '11'].includes(data.typelist)">
      <div class="list-title">起止时间：</div>
      <div class="list-content">{{ data.startime }} 至 {{ data.endtime }}</div>
    </div>
    <div class="list" v-if="['0'].includes(data.typelist)">
      <div class="list-title">请假时长：</div>
      <div class="list-content">{{ data.totalhour }} 小时</div>
    </div>
    <div class="list" v-if="['3', '5', '9'].includes(data.typelist)">
      <div class="list-title">{{ { 3: '补卡时间', 5: '离职时间', 9: '发放时间' }[data.typelist] }}：</div>
      <div class="list-content">{{ data.endtime }}</div>
    </div>
    <div class="list" v-if="['2', '8'].includes(data.typelist)">
      <div class="list-title">{{ { 2: '报销金额', 8: '当前工资' }[data.typelist] }}：</div>
      <div class="list-content">{{ data.money }}</div>
    </div>
    <div class="list" v-if="['8'].includes(data.typelist)">
      <div class="list-title">调整额度：</div>
      <div class="list-content">{{ data.changmoney }}</div>
    </div>

    <template v-if="['10'].includes(data.typelist)">
      <div class="list">
        <div class="list-title">案件名称：</div>
        <div class="list-content">{{ data.title }}</div>
      </div>
      <div class="list">
        <div class="list-title">案件编号：</div>
        <div class="list-content">{{ data.numeration }}</div>
      </div>
      <div class="list">
        <div class="list-title">客户名称：</div>
        <div class="list-content">{{ data.username }}</div>
      </div>
    </template>
    <template v-if="['6'].includes(data.typelist)">
      <div class="list">
        <div class="list-title">案件名称：</div>
        <div class="list-content">{{ data.username }}</div>
      </div>
      <div class="list">
        <div class="list-title">客户地址：</div>
        <div class="list-content">{{ data.useraddr }}</div>
      </div>
      <div class="list">
        <div class="list-title">发票抬头：</div>
        <div class="list-content">{{ data.titlehead }}</div>
      </div>
      <div class="list">
        <div class="list-title">发票金额：</div>
        <div class="list-content">{{ data.money }}</div>
      </div>
      <div class="list">
        <div class="list-title">案件名称：</div>
        <div class="list-content">{{ data.title }}</div>
      </div>
      <div class="list">
        <div class="list-title">是否邮寄：</div>
        <div class="list-content">{{ ['是', '否'][data.mail] }}</div>
      </div>
      <div class="list">
        <div class="list-title">是否传票：</div>
        <div class="list-content">{{ ['是', '否'][data.citation] }}</div>
      </div>
    </template>
    <div class="list">
      <div class="list-title">原因：</div>
      <div class="list-content">{{ data.content }}</div>
    </div>
    <div class="list">
      <div class="list-title">图片：</div>
      <div class="list-content">
        <img class="img" :src="item" v-for="item in data.images" :key="item" alt="" />
      </div>
    </div>
    <div class="list">
      <div class="list-title">备注：</div>
      <div class="list-content">{{ data.typesubhead ? data.typesubhead : '无' }}</div>
    </div>
    <div class="list">
      <div class="list-title">部门经理：</div>
      <div class="list-content">{{ data.adminName }}</div>
    </div>
    <div class="list" v-for="item in data.plan" :key="item.id">
      <div class="list-title">{{ item.groupname }}：</div>
      <div class="list-content">{{ item.username }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      typeList: ['请假', '用车', '报销', '补卡', '转正', '离职', '开票', '外出', '工资调整', '福利', '文档', '加班']
    }
  }
}
</script>

<style lang="scss" scoped>
.newCreate {
  padding: 0 20px;
}
.list {
  display: flex;
  padding: 10px 0;
  font-size: 0.94rem;
  color: #333333;
  font-weight: 400;
  .list-title {
    text-align: right;
    width: 100px;
  }
}
.img {
  width: 9rem;
  height: 7rem;
  border-radius: 1rem;
}
</style>
