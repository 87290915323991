import request from "./request";

// 获取审批类别
export function getTypes() {
	return request({
		url: "/workingapprove/index",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
	}).then(res => {
		res.data.datas.forEach(e => {
			e.isChecked = false;
		});
		res.data.datas.unshift({ id: "", isChecked: true, name: "全部" });
		return res.data.datas;
	});
}

// 提交审批
export function submitApproval(data) {
	return request({
		url: "/workingapprove/approve",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 人员审批流程
export function flowPath(data) {
	return request({
		url: "/Workingapprove/flowpath",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 查询审批记录列表
export function infoPageList(data) {
	return request({
		url: "/workingapprove/infoPageList",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 审批审核
export function checkApprove(data) {
	return request({
		url: "/workingapprove/checkApprove",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}
