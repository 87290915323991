<template>
  <div class="contacts-page view-page">
    <c-search searchTitle="客户姓名/电话/编号" valueFormat="yyyyMMdd" @search="getKeyWord" placeholder="姓名/电话" :isDate="true"></c-search>
    <div class="list">
      <!-- 第一行类别 -->
      <div class="list1">
        <c-navigation @change="changeDepartment" title="部门" :list="$store.state.departmentListAll"></c-navigation>
        <c-navigation @change="changeState" title="审批状态" :list="approvalState"></c-navigation>
      </div>
      <!-- 第二行类别 -->
      <div class="list2" v-if="approvalItems.length > 0">
        <c-navigation @change="changeItem" title="审批事项" :list="approvalItems"></c-navigation>
      </div>
      <c-operate>
        <template #right>
          <div class="btn-list addApproval" @click="isCreate = true">新建审批</div>
        </template>
      </c-operate>
      <c-table :data="tableData">
        <el-table-column align="center" prop="numeration" label="编号">
          <template #default="{ row }">
            <el-checkbox></el-checkbox>
            {{ row.numeration }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="section" label="部门"> </el-table-column>
        <el-table-column align="center" prop="adminName" label="姓名"> </el-table-column>
        <el-table-column align="center" prop="adminMobile" label="手机号"> </el-table-column>
        <el-table-column align="center" prop="typelist" label="审批事项">
          <template #default="{ row }"> {{ approvalItems[Number(row.typelist) + 1].name }} </template>
        </el-table-column>
        <el-table-column align="center" prop="typesubhead" label="备注"> </el-table-column>
        <el-table-column align="center" prop="status" label="审批状态"> </el-table-column>
        <el-table-column align="center" prop="createtime" label="创建时间" width="180"> </el-table-column>
        <el-table-column align="center" prop="date" label="操作" width="120">
          <template #default="{ row }">
            <div class="operate">
              <p class="edit" @click="edit(row)">查看</p>
            </div>
          </template>
        </el-table-column>
      </c-table>
    </div>

    <!-- 新建审批 -->
    <c-drawer v-model="isCreate" :width="35" title="新增审批">
      <new-create v-if="approvalItems.length > 0" :options="approvalItems.slice(1)" @cancel="isCreate = false" @add="getList"></new-create>
    </c-drawer>
    <!-- 详情 -->
    <c-drawer v-model="isDetail" :width="35" title="查看详情">
      <read-detail :data="detailData"></read-detail>
    </c-drawer>

    <!-- 分页 -->
    <div class="page">
      <c-pagination @change="changePage" :pageSize="contactsParam.limit" :total="contactsTotal"></c-pagination>
    </div>
  </div>
</template>

<script>
import CDrawer from '@/components/c-drawer'
import newCreate from './components/newCreate'
import readDetail from './components/detail.vue'
import * as approvalApi from '@/api/approval'
export default {
  name: 'Approval',
  data() {
    return {
      isCreate: false,
      isDetail: false,
      // 审批状态
      approvalState: [
        {
          id: '',
          name: '全部',
          isChecked: true
        },
        {
          id: 0,
          name: '进行中',
          isChecked: false
        },
        {
          id: 1,
          name: '已驳回',
          isChecked: false
        },
        {
          id: 2,
          name: '已完成',
          isChecked: false
        }
      ],
      // 列表信息
      contactsParam: {
        search: '',
        section_id: '',
        status: '',
        starday: '',
        endday: '',
        page: 1,
        limit: 7
      },
      contactsTotal: 0,
      // 审批事项
      approvalItems: [],
      // 列表信息
      tableData: [],
      detailData: {}
    }
  },
  components: {
    CDrawer,
    newCreate,
    readDetail
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.approvalItems = await approvalApi.getTypes()
      this.getList()
    },
    // 获取列表数据
    getList() {
      // this.isCreate = false;
      approvalApi.infoPageList(this.contactsParam).then(res => {
        this.contactsTotal = res.data.total
        this.tableData = res.data.data
      })
    },
    // 改变部门
    changeDepartment(item) {},
    // 改变状态
    changeState(item) {
      this.contactsParam.status = item.id
      this.contactsParam.page = 1
      this.getList()
    },
    // 改变类别
    changeItem(item) {
      this.contactsParam.typelist = item.id
      this.contactsParam.page = 1
      this.getList()
    },
    // showNewClient() {
    //   this.drawer = true
    // },
    // 搜索关键字
    getKeyWord(e) {
      this.contactsParam.search = e.keyWord
      if (e.date) {
        this.contactsParam.starday = e.date[0]
        this.contactsParam.endday = e.date[1]
      } else {
        this.contactsParam.starday = ''
        this.contactsParam.endday = ''
      }
      this.getList()
    },
    // 查看详情
    edit(v) {
      this.detailData = v
      this.isDetail = true
    },
    /**
     * 跳转页数
     */
    changePage(i) {
      this.contactsParam.page = i
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.contacts-page {
  background-color: #fff;
}
.list {
  .list1,
  .list2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #ebf0f0;
    .department {
      margin-right: 7.97rem;
    }
  }
  .addApproval {
    background: linear-gradient(249deg, #fc9940, #f37902);
  }
}

.textColor {
  color: #f22323;
}
.table {
  margin: 0 auto;
  width: 78.84rem;
  .operate {
    cursor: pointer;
    .edit {
      color: #333333;
    }
    .follow {
      color: #333;
    }
  }
}
:deep() .el-table__cell {
  color: #333333;
  font-size: 0.83rem;
  text-align: center;
}

:deep() .el-table__body tr:hover > td.el-table__cell {
  background-color: #eaf0f0;
}
.newClient {
  :deep() .el-drawer__header {
    color: #000000;
    font-weight: 400;
    font-size: 1.04rem;
    margin-bottom: 1.25rem;
  }

  :deep() .el-drawer__open .el-drawer.rtl {
    border-radius: 2rem 0rem 0rem 2rem;
    background: #ffffff;
  }
}
</style>
