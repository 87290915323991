<template>
  <div class="newCreate">
    <div class="title">#申请人信息</div>
    <el-form :key="formKey" :inline="true" :model="form" :rules="rules" ref="from" label-width="100px" size="mini"
      class="demo-form-inline">
      <el-row>
        <el-col :span="24">
          <el-form-item label="部门：">
            <el-input disabled type="text" :value="userInfo.section"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="姓名：">
            <el-input disabled type="text" :value="userInfo.username"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="联系方式：">
            <el-input disabled type="text" :value="userInfo.mobile"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="审批事项：" prop="typelist">
            <el-select v-model="form.typelist" placeholder="请选择" @change="changeType">
              <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="[0, 1, 4, 7, 11].includes(form.typelist)">
        <el-col :span="24">
          <el-form-item label="起止时间：" prop="date1">
            <el-date-picker style="width: 25rem" :clearable="false" v-model="timeStartEnd" type="datetimerange"
              range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
              @change="changeTimeStartEnd"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="[0].includes(form.typelist)">
        <el-col :span="24">
          <el-form-item label="请假时长：">
            <el-input disabled v-model="form.totalhour">
              <template slot="append">小时</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="[3, 5, 9].includes(form.typelist)">
        <el-col :span="24">
          <el-form-item :label="{ 3: '补卡时间：', 5: '离职时间：', 9: '发放时间：' }[form.typelist]" prop="date2">
            <el-date-picker v-model="form.endtime" style="width: 25rem" value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime" placeholder="选择时间"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="[2, 8].includes(form.typelist)">
        <el-col :span="24">
          <el-form-item :label="{ 2: '报销金额：', 8: '当前工资：' }[form.typelist]" prop="money">
            <el-input v-model="form.money">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="[8].includes(form.typelist)">
        <el-col :span="24">
          <el-form-item label="调整额度：" prop="changmoney">
            <el-input v-model="form.changmoney">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="[0, 1, 2, 3, 4, 5, 7, 8, 9, 11].includes(form.typelist)">
        <el-col :span="24">
          <el-form-item
            :label="{ 0: '请假事由：', 1: '用车原由：', 2: '报销用途：', 3: '补卡理由：', 4: '转正陈述：', 5: '离职原因：', 7: '外出原由：', 8: '调整原因：', 9: '福利要求：', 11: '加班事由：' }[form.typelist]"
            prop="content">
            <el-input style="width: 25rem" type="textarea" :resize="'none'" :rows="3" v-model="form.content">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <template v-if="[10].includes(form.typelist)">
        <el-row>
          <el-col :span="24">
            <el-form-item label="案件名称：" prop="title">
              <el-input v-model="form.title" type="text" placeholder="请输入案件名称"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="案件编号：" prop="numeration">
              <el-input v-model="form.numeration" type="text" placeholder="请输入案件编号"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="客户名称：" prop="username">
              <el-input v-model="form.username" type="text" placeholder="请输入客户名称"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <el-row v-if="[0, 1, 2, 3, 7, 9, 10, 11].includes(form.typelist)">
        <el-col :span="24">
          <el-form-item label="文件：" prop="file">
            <div style="width: 22rem">
              <c-upload @on-success="changeImg" @on-remove="changeImg" :isDocument="false" multiple :limit="6">
              </c-upload>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <template v-if="[6].includes(form.typelist)">
        <el-row>
          <el-col :span="24">
            <el-form-item label="客户名字：" prop="username">
              <el-input v-model="form.username" type="text" placeholder="请输入客户名字"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="客户地址：" prop="useraddr">
              <el-input v-model="form.useraddr" type="text" placeholder="请输入客户地址"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="发票抬头：" prop="titlehead">
              <el-input v-model="form.titlehead" type="text" placeholder="请输入发票抬头"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="发票金额：" prop="money">
              <el-input v-model="form.money" type="text" placeholder="请输入发票金额"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="案件名称：" prop="title">
              <el-input v-model="form.title" type="text" placeholder="请输入案件名称"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="是否邮寄：">
              <el-radio-group v-model="form.mail">
                <el-radio :label="0">是</el-radio>
                <el-radio :label="1">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="是否传票：">
              <el-radio-group v-model="form.citation">
                <el-radio :label="0">是</el-radio>
                <el-radio :label="1">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <el-row v-if="[6, 10].includes(form.typelist)">
        <el-col :span="24">
          <el-form-item label="备注：" prop="remark">
            <el-input style="width: 22rem" type="textarea" :resize="'none'" :rows="3" v-model="form.remark"> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <template v-if="flowPathList.length">
        <el-row v-for="item in flowPathList" :key="item.id">
          <el-col :span="24">
            <el-form-item :label="item.groupname">
              <el-input disabled type="text" :value="item.username"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <el-row>
        <el-col :span="24">
          <div class="btn">
            <div class="cancel" @click="$emit('cancel')">取消</div>
            <div class="confirm" @click="submit">确定</div>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import _m from 'moment'
import { flowPath, submitApproval } from '@/api/approval.js'
import { mapState } from 'vuex'
export default {
  props: {
    options: {
      type: Array
    }
  },

  data() {
    const validatorDate1 = (rule, value, callback) => {
      return this.timeStartEnd.length ? callback() : callback(new Error(rule.message))
    }
    const validatorDate2 = (rule, value, callback) => {
      return this.form.endtime ? callback() : callback(new Error(rule.message))
    }
    const validatorFile = (rule, value, callback) => {
      return this.imgFile.length ? callback() : callback(new Error(rule.message))
    }
    return {
      formKey: 1,
      timeStartEnd: [],
      timeEnd: '',
      imgFile: [],
      flowPathList: [],
      // form
      form: {},
      rules: {
        typelist: [{ required: true, message: '请选择审批事项', trigger: 'change' }],
        content: [{ required: true, message: '请输入事由', trigger: 'blur' }],
        money: [{ required: true, message: '请输入金额', trigger: 'blur' }],
        changmoney: [{ required: true, message: '请输入调整额度', trigger: 'blur' }],
        username: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        numeration: [{ required: true, message: '请输入编号', trigger: 'blur' }],
        useraddr: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        titlehead: [{ required: true, message: '请输入抬头', trigger: 'blur' }],
        title: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
        date1: [{ type: 'array', required: true, message: '日期是必填字段', validator: validatorDate1, trigger: 'change' }],
        date2: [{ type: 'string', required: true, message: '日期是必填字段', validator: validatorDate2, trigger: 'change' }],
        file: [{ required: true, message: '请上传文件', validator: validatorFile, trigger: 'change' }]
      }
    }
  },
  computed: {
    ...mapState('user', ['userInfo'])
  },
  mounted() {
    console.log('🚀 userInfo：', this.userInfo)
  },
  methods: {
    // 获取审批人员列表
    getFlowPathList(type) {
      flowPath({ type }).then(res => {
        this.flowPathList = res.data
        this.flowPathList.forEach(i => {
          this.form.plan += `${i.id},`
        })
      })
    },
    // 改变审批事项
    changeType() {
      const type = this.form.typelist
      this.form = {
        images: '',
        plan: ''
      }
      this.$set(this.form, 'typelist', type)
      this.formKey++
      this.timeStartEnd = []
      this.timeEnd = ''
      this.imgFile = []
      if ([2, 6, 8, 9].includes(type)) {
        this.getFlowPathList(2)
      } else {
        this.getFlowPathList(1)
      }
    },
    // 改变时间
    changeTimeStartEnd(e) {
      this.form.startime = e[0]
      this.form.endtime = e[1]
      const time1 = _m(e[0])
      const time2 = _m(e[1])
      this.$set(this.form, 'totalhour', time2.diff(time1, 'hours'))
    },
    // 改变图片列表
    changeImg(file) {
      this.imgFile = file
    },
    // 确定
    submit() {
      this.$refs['from'].validate(valid => {
        if (valid) {
          this.imgFile.forEach(res => {
            this.form.images += res.url
          })
          submitApproval(this.form).then(res => {
            this._showMsg('新建成功')
            this.$emit('cancel')
            this.$emit('add')
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  padding: 1.5rem;
  font-size: 0.94rem;
  color: #333333;
  font-weight: 400;
}

.btn {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 3.05rem 0;

  div {
    width: 5.56rem;
    height: 2.2rem;
    text-align: center;
    line-height: 2.22rem;
    background: #e5ebeb;
    border-radius: 0.78rem;
    color: #000000;
    font-weight: 400;
    font-size: 0.94rem;
  }

  .confirm {
    background: linear-gradient(46deg, #1db8ab, #179d92);
    margin-left: 3.75rem;
    color: #ffffff;
  }
}
</style>
